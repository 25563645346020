import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InsertCards = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [card, setCard] = useState({
    paletteNumber: '',
    numbers: {
      B: Array(5).fill(''),
      I: Array(5).fill(''),
      N: Array(5).fill(''),
      G: Array(5).fill(''),
      O: Array(5).fill(''),
    },
  });
  const [hasNonUniqueNumbers, setHasNonUniqueNumbers] = useState(false);

  useEffect(() => {
    checkUniqueness();
    if (hasNonUniqueNumbers){
      toast.error('Please ensure all numbers are unique')
    }
  }, [card.numbers]);

  const checkUniqueness = () => {
    const allNumbers = Object.values(card.numbers)
      .flat()
      .filter(num => num !== '' && num !== 'FREE')
      .map(num => parseInt(num, 10));
    
    const uniqueNumbers = new Set(allNumbers);
    const newHasNonUniqueNumbers = allNumbers.length !== uniqueNumbers.size;
    
    console.log('All numbers:', allNumbers);
    console.log('Unique numbers:', Array.from(uniqueNumbers));
    console.log('Has non-unique numbers:', newHasNonUniqueNumbers);
    
    setHasNonUniqueNumbers(newHasNonUniqueNumbers);
  };

  const generateRandomNumbers = () => {
    const generateUniqueNumbers = (min, max, count) => {
      const numbers = new Set();
      while (numbers.size < count) {
        numbers.add(Math.floor(Math.random() * (max - min + 1)) + min);
      }
      return Array.from(numbers);
    };

    const randomNumbers = {
      B: generateUniqueNumbers(1, 15, 5),
      I: generateUniqueNumbers(16, 30, 5),
      N: [...generateUniqueNumbers(31, 45, 4), 'FREE'],
      G: generateUniqueNumbers(46, 60, 5),
      O: generateUniqueNumbers(61, 75, 5),
    };

    setCard((prevCard) => ({
      ...prevCard,
      numbers: randomNumbers,
    }));
  };

  const API = process.env.REACT_APP_API;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasNonUniqueNumbers) {
      toast.error('Cannot submit card with non-unique numbers');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.post(`${API}/card/insert`, { card }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setCard({
        paletteNumber: '',
        numbers: {
          B: Array(5).fill(''),
          I: Array(5).fill(''),
          N: Array(5).fill(''),
          G: Array(5).fill(''),
          O: Array(5).fill(''),
        },
      });
      toast.success('Inserted card successfully');
    } catch (error) {
      console.error('Error inserting card:', error.message);
      toast.error('Error inserting card');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardChange = (column, rowIndex, value) => {
    setCard((prevCard) => ({
      ...prevCard,
      numbers: {
        ...prevCard.numbers,
        [column]: prevCard.numbers[column].map((num, index) => (index === rowIndex ? value : num)),
      },
    }));
  };

  return (
    <div className="mx-auto w-full max-w-lg">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4 text-yellow-200">Insert Card</h2>
      <button
        type="button"
        onClick={generateRandomNumbers}
        className="mt-4 bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded mr-4"
      >
        Generate Random Numbers
      </button>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="paletteNumber" className="block text-yellow-100 font-bold mb-2">
            Card Number
          </label>
          <input
            type="number"
            id="paletteNumber"
            placeholder="Number"
            value={card.paletteNumber}
            onChange={(e) => setCard({ ...card, paletteNumber: e.target.value })}
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
          />
        </div>
        <table className="w-full border-collapse">
          <thead>
            <tr>
              <th className="bg-yellow-500 text-white px-4 py-2">B</th>
              <th className="bg-yellow-500 text-white px-4 py-2">I</th>
              <th className="bg-yellow-500 text-white px-4 py-2">N</th>
              <th className="bg-yellow-500 text-white px-4 py-2">G</th>
              <th className="bg-yellow-500 text-white px-4 py-2">O</th>
            </tr>
          </thead>
          <tbody>
            {[...Array(5)].map((_, rowIndex) => (
              <tr key={rowIndex}>
                {['B', 'I', 'N', 'G', 'O'].map((column) => (
                  <td key={column} className="border border-gray-300 px-4 py-2">
                    {column === 'N' && rowIndex === 2 ? (
                      <input
                        type="text"
                        value="FREE"
                        disabled
                        className="w-full px-1 py-2 bg-gray-100 text-gray-500 rounded-md"
                      />
                    ) : (
                      <input
                        type="number"
                        value={card.numbers[column][rowIndex]}
                        onChange={(e) => handleCardChange(column, rowIndex, e.target.value)}
                        required
                        className="w-full px-2 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-yellow-500"
                      />
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {hasNonUniqueNumbers && (
          <p className="text-red-500 mt-2">Warning: Non-unique numbers detected. Please ensure all numbers are unique.</p>
        )}
        <button
          type="submit"
          disabled={hasNonUniqueNumbers || isLoading}
          className={`mt-4 ${
            hasNonUniqueNumbers || isLoading
              ? 'bg-gray-400 cursor-not-allowed'
              : 'bg-yellow-500 hover:bg-yellow-600'
          } text-white font-bold py-2 px-4 rounded`}
        >
          {isLoading ? 'Inserting...' : 'Insert Card'}
        </button>
      </form>
    </div>
  );
};

export default InsertCards;