import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Form, Table, Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const EditCard = ({ card, onClose, onUpdate }) => {
  const [editedCard, setEditedCard] = useState(card);
  const [isLoading, setIsLoading] = useState(false);
  const [hasDuplicates, setHasDuplicates] = useState(false);
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    checkForDuplicates();
  }, [editedCard]);

  const checkForDuplicates = () => {
    const allNumbers = Object.values(editedCard.numbers).flat();
    const uniqueNumbers = new Set(allNumbers);
    const duplicatesExist = allNumbers.length !== uniqueNumbers.size;
    setHasDuplicates(duplicatesExist);

    if (duplicatesExist) {
      toast.warning('Duplicate numbers detected. Please remove duplicates before updating.', {
        autoClose: false,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasDuplicates) {
      toast.error('Cannot update card with duplicate numbers.');
      return;
    }
    setIsLoading(true);
    try {
      const response = await axios.put(`${API}/card/editCard/${editedCard.paletteNumber}`, editedCard, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      onUpdate(response.data.card);
      toast.success('Card Updated Successfully.');
    } catch (error) {
      toast.error('Error Updating Card.');
      console.error('Error updating card:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCardChange = (column, rowIndex, value) => {
    setEditedCard((prevCard) => ({
      ...prevCard,
      numbers: {
        ...prevCard.numbers,
        [column]: prevCard.numbers[column].map((num, index) => (index === rowIndex ? value : num)),
      },
    }));
  };

  return (
    <Modal show={true} onHide={onClose}>
      <ToastContainer />
      <Modal.Header closeButton>
        <Modal.Title>Edit Card</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="w-25">
            <Form.Label>Card Number</Form.Label>
            <Form.Control type="number" value={editedCard.paletteNumber} disabled />
          </Form.Group>
          <Table bordered>
            <thead>
              <tr>
                <th>B</th>
                <th>I</th>
                <th>N</th>
                <th>G</th>
                <th>O</th>
              </tr>
            </thead>
            <tbody>
              {[...Array(5)].map((_, rowIndex) => (
                <tr key={rowIndex}>
                  {['B', 'I', 'N', 'G', 'O'].map((column) => (
                    <td key={column}>
                      {column === 'N' && rowIndex === 2 ? (
                        <Form.Control type="text" value="FREE" disabled />
                      ) : (
                        <Form.Control
                          type="text"
                          value={editedCard.numbers[column][rowIndex]}
                          onChange={(e) => handleCardChange(column, rowIndex, e.target.value)}
                          required
                        />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          <Button variant="primary" type="submit" disabled={isLoading || hasDuplicates}>
            Update Card
          </Button>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditCard;