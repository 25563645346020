import React, { useState, useEffect } from 'react';

const LoadingOverlay = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          clearInterval(timer);
          return 100;
        }
        return prevProgress + 1;
      });
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex flex-col items-center justify-center z-50 bg-gradient-to-r from-red-500 to-yellow-500">
      <div className="text-center text-white text-shadow">
        <h1 className="text-4xl mb-2">Loading...</h1>
        <p className="text-2xl">Get ready for an amazing experience!</p>
        <p className="text-3xl mt-4">{progress}%</p>
      </div>
      <div className="relative w-48 h-48 mt-10">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white animate-bounce animation-delay-0"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white animate-bounce animation-delay-200"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white animate-bounce animation-delay-400"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white animate-bounce animation-delay-600"></div>
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-10 h-10 rounded-full bg-white animate-bounce animation-delay-800"></div>
      </div>
    </div>
  );
};

export default LoadingOverlay;