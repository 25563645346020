import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './DashBoard.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FaChevronDown, FaStar, FaRedo } from 'react-icons/fa'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DashboardPage = () => {
  const [bettingAmount, setBettingAmount] = useState(localStorage.getItem('bettingAmount') || '');
  const [audioLanguage, setAudioLanguage] = useState(localStorage.getItem('audioLanguage') || '');
  const [callSpeed, setCallSpeed] = useState(localStorage.getItem('callSpeed') || '');
  const [winningPattern, setWinningPattern] = useState(localStorage.getItem('winningPattern') || '');
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [slideshowTables, setSlideshowTables] = useState([]);
  const API = process.env.REACT_APP_API;
  const [isLoading, setIsLoading] = useState(false);
  const [adminWalletBalance, setAdminWalletBalance] = useState(null);


  useEffect(() => {
    if (!token) {
      navigate('/login');
    }
    fetchAdminWalletBalance();
  }, [token, navigate]);

  const ReloadIcon = ({ onClick }) => {
    return (
      
        <FaRedo onClick={onClick} style={{color: 'green', cursor: 'pointer'}}/>
    );
  };

  useEffect(() => {
    if (shouldDisplayBalanceToast()) {
      toast.warning(`Wallet Balance Low: ${adminWalletBalance}`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }, [adminWalletBalance]);
  
  const fetchAdminWalletBalance = async () => {
    try {
      const response = await axios.get(`${API}/admin/wallet-balance`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setAdminWalletBalance(response.data.balance);
    } catch (error) {
      console.error('Error fetching admin wallet balance:', error);
    }
  };
  
  const shouldDisplayBalanceToast = () => {
    const lastTimestamp = localStorage.getItem('lastBalanceToastTimestamp');
    const currentTimestamp = Date.now();
    const sixHoursInMilliseconds = 1 * 60 * 60 * 1000;
  
    if (
      adminWalletBalance !== null &&
      (!lastTimestamp || currentTimestamp - lastTimestamp > sixHoursInMilliseconds) && adminWalletBalance < 2000
    ) {
      localStorage.setItem('lastBalanceToastTimestamp', Date.now());
      return true;
    }
    return false;
  };

  const handleReloadLastGame = () => {
    const storedSelectedNumbers = JSON.parse(localStorage.getItem('selectedNumbers')) || [];
    setSelectedNumbers(storedSelectedNumbers);
  };
  

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const winningPatterns = [
    [1, 0, 0, 0, 0, 6, 0, 0, 0, 0, 10, 0, 12, 0, 0, 15, 0, 0, 0, 0, 21, 0, 0, 0, 0],
    [0, 2, 0, 0, 0, 0, 7, 0, 0, 0, 0, 11, 12, 0, 0, 0, 17, 0, 0, 0, 0, 22, 0, 0, 0],
    [0, 0, 3, 0, 0, 0, 0, 8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 18, 0, 0, 0, 0, 23, 0, 0],
    [0, 0, 0, 4, 0, 0, 0, 0, 9, 0, 0, 0, 12, 13, 0, 0, 0, 0, 19, 0, 0, 0, 0, 24, 0],
    [0, 0, 0, 0, 5, 0, 0, 0, 0, 10, 0, 0, 12, 0, 14, 0, 0, 0, 0, 20, 0, 0, 0, 0, 25],
    
    [1, 2, 3, 4, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 6, 7, 8, 9, 10, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 13, 12, 14, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 16, 17, 18, 19, 20, 0, 0, 0, 0, 0],
    [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 22, 23, 24, 25],

    [1, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 19, 0, 0, 0, 0, 0, 25],
    [0, 0, 0, 0, 5, 0, 0, 0, 10, 0, 0, 0, 12, 0, 0, 0, 17, 0, 0, 0, 21, 0, 0, 0, 0],

    [1, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 0, 0, 0, 25],
    [0, 0, 0, 0, 0, 0, 7, 0, 9, 0, 0, 0, 12, 0, 0, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0],
  ];

  useEffect(() => {
      const tables = winningPatterns.map((pattern) => generateTable(pattern));
      setSlideshowTables(tables);
    }, []);

  const handleNumberSelect = (number) => {
    if (selectedNumbers.includes(number)) {
      setSelectedNumbers(selectedNumbers.filter((n) => n !== number));
    } else {
      setSelectedNumbers([...selectedNumbers, number]);
    }
    localStorage.setItem('selectedNumbers', JSON.stringify([...selectedNumbers, number]));
  };

  const handleStartGame = async () => {
    setIsLoading(true);
    try {  
      const response = await axios.post(`${API}/game/start`, {
        bettingAmount: parseInt(bettingAmount),
        cardPaletteNumbers: selectedNumbers,
        callSpeed: parseInt(callSpeed),
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      navigate('/game-in-progress', { state: { gameId: response.data.gameId, calledNumbers: response.data.calledNumbers, playingCards: response.data.playingCards, payoutToWinner: response.data.payoutToWinner, callSpeed, audioLanguage } });
    } catch (error) {
      console.error('Error starting the game:', error);
      if (error.response.status === 400 && error.response.data.message == 'Insufficient balance in wallet') {
        toast.error('Insufficient balance in wallet');
      }
      if (error.response.status === 403) {
        toast.error(error.response.data.message);
      }
    }finally {
      setIsLoading(false);
    }
  };

  const handleLocalStorage = () => {
      localStorage.setItem('bettingAmount', bettingAmount);
      localStorage.setItem('callSpeed', callSpeed);
      localStorage.setItem('audioLanguage', audioLanguage);
      localStorage.setItem('winningPattern', winningPattern);
    };
  
    const generateTable = (pattern) => {
      const cellSize = 25;
      const dotSize = 25;
      const cells = pattern.map((value, index) => (
        <td
          key={index}
          className="relative py-3 px-4 w-3 h-3 bg-gray-200 border border-black"
        >
          <div
            className={`absolute inset-0 m-auto rounded-full ${
              value === 12
                ? 'bg-yellow-400 animate-pulse'
                : value > 0
                ? 'bg-blue-700'
                : 'bg-transparent'
            }`}
            style={{ width: `${dotSize}px`, height: `${dotSize}px` }}
          ></div>
        </td>
      ));
    
      const rows = [];
      for (let i = 0; i < 5; i++) {
        rows.push(
          <tr key={i} className="grid grid-cols-5">
            {cells.slice(i * 5, (i + 1) * 5)}
          </tr>
        );
      }
    
      const headerCells = ['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
        <th
          key={index}
          className={`font-bold text-center text-white ${
            ['bg-blue-500', 'bg-red-500', 'bg-orange-500', 'bg-green-500', 'bg-yellow-400'][index]
          } w-15 h-10 text-2xl border border-white`}
        >
          {letter}
        </th>
      ));
    
      return (
        <table className="mb-0 pb-0 bg-gray-200">
          <thead>
            <tr className="grid grid-cols-5">{headerCells}</tr>
          </thead>
          <tbody className="mb-0 pb-0 bg-gray-200">{rows}</tbody>
        </table>
      );
    };
    
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      arrows: false,
    };

  const ProfileIcon = ({ onClick }) => {
    return (
      <div className='d-flex'>
      <div
        style={{
          width: '50px',
          height: '50px',
          borderRadius: '50%',
          backgroundColor: '#ccc',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer'
        }}
        onClick={onClick}
      >
        <FaStar
        />
      </div>
      </div>
    );
  };

  const handleProfileClick = () => {
      navigate('/reports')
    };

  const url = window.location.href;
  

  return (
    <div className="bg-gray-900 min-h-screen">
      <ToastContainer/>
      <div className="flex justify-start pt-4 px-4">
        <ProfileIcon onClick={handleProfileClick} />
      </div>
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-12 gap-8">
          <div className="col-span-9 bg-gray-800 p-4 rounded-lg">
            <div className="grid grid-cols-2 gap-4 text-white mb-4">
              <div>
                <label htmlFor="bettingAmount" className="block mb-2">
                  Bet Amount
                </label>
                <input
                  list="bettingAmountOptions"
                  className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none"
                  value={bettingAmount}
                  onChange={(e) => setBettingAmount(e.target.value)}
                  placeholder="Select or type Bet Amount"
                  onBlur={handleLocalStorage}
                />
                <datalist id="bettingAmountOptions">
                  <option value="10"></option>
                  <option value="15"></option>
                  <option value="20"></option>
                  <option value="25"></option>
                  <option value="50"></option>
                  <option value="100"></option>
                  <option value="150"></option>
                  <option value="200"></option>
                </datalist>
              </div>
              <div>
                <label htmlFor="payoutToWinner" className="block mb-2">
                  Winning Pattern
                </label>
                <select
                  id="winningPattern"
                  className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none"
                  onBlur={handleLocalStorage}
                  value={winningPattern}
                  onChange={(e) => setWinningPattern(e.target.value)}
                >
                  <option value="">select an option</option>
                  <option value="All">All Common Patterns</option>
                  <option value="Am">Full House</option>
                  <option value="Am">L Shape</option>
                  <option value="Am">Both Diagonal Line</option>
                </select>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label htmlFor="audioLanguage" className="block mb-2 text-white">
                  Audio Language
                </label>
                <select
                  id="audioLanguage"
                  className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none"
                  value={audioLanguage}
                  onChange={(e) => setAudioLanguage(e.target.value)}
                  onBlur={handleLocalStorage}
                >
                  <option value="">select an option</option>
                  <option value="male">Amharic Male</option>
                  <option value="female">Amharic Female</option>
                  <option value="male">English Male</option>
                  <option value="female">English Female</option>
                  <option value="male">Tigrigna Male</option>
                  <option value="female">Tigrigna Female</option>
                </select>
              </div>
              <div>
                <label htmlFor="callSpeed" className="block mb-2 text-white">
                  Call Speed
                </label>
                <select
                  id="callSpeed"
                  className="w-full px-4 py-2 bg-gray-700 text-white rounded-lg focus:outline-none"
                  value={callSpeed}
                  onChange={(e) => setCallSpeed(e.target.value)}
                  onBlur={handleLocalStorage}
                >
                  <option value="">Select Call Speed</option>
                  <option value="5000">5 seconds</option>
                  <option value="6000">6 seconds</option>
                  <option value="7000">7 seconds</option>
                  <option value="10000">10 seconds</option>
                  <option value="15000">15 seconds</option>
                </select>
              </div>
              <ReloadIcon onClick={handleReloadLastGame} className="text-success"/>
            </div>
            <div className="mt-4 border border-gray-600 rounded-lg overflow-y-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-800">
              <div className="grid grid-cols-10 gap-2 p-2 text-xl">
                {Array.from({ length: 100 }, (_, i) => (
                  <button
                    key={i}
                    className={`px-2 py-1 rounded-md font-bold ${
                      selectedNumbers.includes(i + 1)
                        ? 'bg-yellow-600 text-white'
                        : 'text-gray-400'
                    }`}
                    onClick={() => handleNumberSelect(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-span-3">
            <div className="bg-gray-800 rounded-lg p-4 mb-4">
              <Slider {...settings}>
                {slideshowTables.map((table, index) => (
                  <div key={index}>{table}</div>
                ))}
              </Slider>
            </div>
            <button
              className="w-full py-3 bg-yellow-500 text-white font-bold rounded-lg hover:bg-yellow-600 focus:outline-none mt-auto"
              onClick={handleStartGame}
              disabled={!bettingAmount || !callSpeed || selectedNumbers.length < 3 || isLoading}
            >
              {isLoading ? 'Creating Game...' : 'Create Game'}
            </button>
          </div>
        </div>
      </div>
      <div className="fixed bottom-4 right-4">
        <button
          onClick={handleLogout}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg"
        >
          Log Out
        </button>
      </div>
    </div>
  );
};

export default DashboardPage;
