import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FaStar, FaDice } from 'react-icons/fa';

export async function login(username, password) {
  const API = process.env.REACT_APP_API;

  try {
    const response = await fetch(`${API}/admin/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password }),
    });

    const data = await response.json();

    if (response.ok) {
      if (data.token) {
        localStorage.setItem('token', data.token);
        return data;
      } else {
        throw new Error('Unauthorized');
      }
    } else {
      throw new Error(data.message);
    }
  } catch (err) {
    throw err;
  }
}

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [isPower, setIsPower] = useState(false);

  useEffect(() => {
    if (window.location.href.includes('achawach')) {
      setIsPower(true);
    } else {
      setIsPower(false);
    }
  }, [username, location.pathname]);

  if (isPower){
    document.title = 'Achawach Bingo - Login';
  } 
  else {
    document.title = 'Tom Bingo';
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data = await login(username, password);
      navigate('/dashboard');
    } catch (err) {
      toast.error(err.message);
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  if (isPower) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 to-gray-800 text-white flex items-center justify-center">
        <div className="max-w-md w-full px-6 py-8 bg-gray-900 bg-opacity-70 rounded-lg shadow-lg">
          <div className="flex items-center justify-center mb-8">
            <FaStar className="text-6xl text-yellow-500 mr-4" />
            <h5 className="text-4xl font-bold text-yellow-500">Achawach Bingo</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="username" className="block mb-2 font-bold text-yellow-500">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <label htmlFor="password" className="block mb-2 font-bold text-yellow-500">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-4 py-2 bg-gray-800 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-yellow-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-6 bg-yellow-500 text-gray-900 font-bold rounded-lg hover:bg-yellow-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 focus:ring-offset-gray-900"
              disabled={isLoading || !username.includes('zola')}
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </button>
          </form>
        </div>
        <ToastContainer />
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center">
      <div className="max-w-md w-full px-6 py-8 bg-white rounded-lg shadow-lg">
          <div className="flex items-center justify-center mb-8">
            <FaDice className="text-6xl text-yellow-500 mr-4" />
            <h5 className="text-4xl font-bold text-yellow-500">Tom Bingo</h5>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="username" className="block mb-2 font-bold text-yellow-500">
                Username
              </label>
              <input
                type="text"
                id="username"
                className="w-full px-4 py-2 border border-yellow-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>
            <div className="mb-8">
              <label htmlFor="password" className="block mb-2 font-bold text-yellow-500">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-4 py-2 border border-yellow-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 px-6 bg-yellow-600 text-white font-bold rounded-lg hover:bg-yellow-700 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
              disabled={isLoading || username.includes('zola')}
            >
              {isLoading ? 'Signing in...' : 'Sign in'}
            </button>
          </form>
      </div>
      <ToastContainer />
    </div>
  );
}

export default Login;