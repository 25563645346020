import React, {useEffect, useState} from 'react';
import { Link, useLocation } from 'react-router-dom';

function Navbar() {
  const [adminName, setAdminName] = useState(null);
  const [walletBalance, setwalletBalance] = useState(null);
  const location = useLocation();
  const token = localStorage.getItem('token');
  const API = process.env.REACT_APP_API;

  useEffect(() => {
    const fetchAdminName = async () => {
      try {
        const response = await fetch(`${API}/admin/name`, {
          headers: { Authorization: `Bearer ${token}`}  
        });
        console.log(response);
        const data = await response.json();
        setAdminName(data.adminName);
        setwalletBalance(data.walletBalance);
      
      } catch (error) {
        console.error('Error fetching admin name:', error);
      }
    };

    fetchAdminName();
  }, []);

  if (location.pathname === '/game-in-progress') {
    return null;
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand bg-warning" to="/dashboard">Tom Bingo</Link>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/dashboard">Dashboard</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/all-cards">All Cards</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/my-games">My Games</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/my-stats">My Stats</Link>
            </li>
          </ul>
          </div>
          <ul className="navbar-nav ml-auto fst-italic" style={{ marginRight: '10px' }}> 
            {adminName ? (
                <li>
                  <span className="btn btn-success fw-b text-white">Wallet: {walletBalance}</span>
                </li>
              ) : ''
            }
          </ul>
          <ul className="navbar-nav ml-auto mr-2 fst-italic "> 
            {adminName ? (
              <li className="nav-item">
                <span className="btn bg-secondary fw-b text-white">{adminName}</span>
              </li>
            ) : (
              <li className="nav-item">
                <Link className="nav-link text-white" to="/login">Login</Link>
              </li>
            )}
          </ul>
        
      </div>
    </nav>
  );
}

export default Navbar;
