import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GenerateCards = () => {
  const API = process.env.REACT_APP_API;
  const [numCards, setNumCards] = useState('');

  const handleGenerateCards = async () => {
    const password = prompt('Please enter the password:');
    if (password === '##909090') {
      try {
        const response = await axios.post(
          `${API}/card/generate`,
          { numCards: parseInt(numCards) },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          }
        );
        console.log('Cards generated successfully:', response.data);
        toast.success('Cards generated successfully.');
        setNumCards('');
      } catch (error) {
        console.error('Error generating cards:', error);
        toast.error('Error generating cards', error);
      }
    } else {
      toast.error('Incorrect password. Card generation failed.');
    }
  };

  const handleNumCardsChange = (event) => {
    setNumCards(event.target.value);
  };

  return (
    <div className="bg-yellow-900 text-white p-4">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4">Generate Cards</h2>
      <div className="mb-4">
        <label htmlFor="numCards" className="block mb-2">
          Number of Cards:
        </label>
        <input
          type="number"
          id="numCards"
          value={numCards}
          onChange={handleNumCardsChange}
          className="w-full px-3 py-2 text-black rounded"
          min="1"
          required
        />
      </div>
      <button
        onClick={handleGenerateCards}
        className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
      >
        Generate Cards
      </button>
    </div>
  );
};

export default GenerateCards;