import React, { useState, useEffect } from 'react';
import { FaDice, FaMoneyBill } from 'react-icons/fa';

function AdminGames() {
  const [games, setGames] = useState([]);
  const API = process.env.REACT_APP_API;

  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0,
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [gamesPerPage] = useState(20);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setStats(data);
    };
    fetchStats();
  }, []);

  useEffect(() => {
    const fetchGames = async () => {
      try {
        const token = localStorage.getItem('token');
        const url = new URL(`${API}/admin/myGames`);
        if (startDate) url.searchParams.append('startDate', startDate);
        if (endDate) url.searchParams.append('endDate', endDate);
        const response = await fetch(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        const data = await response.json();
        const filteredGames = data.filter((game) => {
          const gameDate = new Date(game.date);
          const start = startDate ? new Date(startDate) : null;
          const end = endDate ? new Date(endDate) : null;
  
          if (start && end) {
            return gameDate >= start && gameDate <= end;
          } else if (start) {
            return gameDate >= start;
          } else if (end) {
            return gameDate <= end;
          } else {
            return true;
          }
        });
  
        setGames(filteredGames);
        setCurrentPage(1);
      } catch (error) {
        console.log(error);
      }
    };
    fetchGames();
  }, [startDate, endDate]);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const year = date.getFullYear();
    const month = months[date.getMonth()];
    const day = date.getDate();
    const dayOfWeek = days[date.getDay()];
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

    return `${dayOfWeek}, ${month} ${day}, ${year} ${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const indexOfLastGame = currentPage * gamesPerPage;
  const indexOfFirstGame = indexOfLastGame - gamesPerPage;
  const currentGames = games.slice(indexOfFirstGame, indexOfLastGame);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="my-3 mx-4 p-3">
      <h2 className="text-2xl font-bold mb-4 text-white">Games</h2>
      <div className="flex space-x-8 mb-8">
        <div className="flex items-center p-4 bg-yellow-800 rounded-lg shadow-md">
          <FaDice className="text-4xl text-red-500 mr-4" />
          <span className="text-2xl font-bold text-white">{stats.totalGames} Games</span>
        </div>
        <div className="flex items-center p-4 bg-yellow-800 rounded-lg shadow-md">
          <FaMoneyBill className="text-4xl text-red-500 mr-4" />
          <span className="text-2xl font-bold text-white">{stats.wallet} ብር</span>
        </div>
      </div>
      <div className="mb-4 flex space-x-4">
        <div>
          <label htmlFor="startDate" className="block text-sm font-medium text-white">Start Date</label>
          <input
            type="date"
            id="startDate"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            className="mt-1 p-2 block w-full  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
        <div>
          <label htmlFor="endDate" className="block text-sm font-medium text-white">End Date</label>
          <input
            type="date"
            id="endDate"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            className="mt-1 block w-full p-2 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse bg-white rounded-lg shadow-lg">
          <thead>
            <tr className="bg-yellow-500 text-white">
              <th className="px-6 py-3">ቀን</th>
              <th className="px-6 py-3">ውርርድ መጠን</th>
              <th className="px-6 py-3">የተጫዋቾች ብዛት</th>
              <th className="px-6 py-3">ዛት</th>
              <th className="px-6 py-3">ሁኔታ</th>
              <th className="px-6 py-3">ጠቅላላ ውርርድ መጠን</th>
              <th className="px-6 py-3">ትርፍ</th>
            </tr>
          </thead>
          <tbody>
            {currentGames.map((game, index) => (
              <tr key={game.id} className={index % 2 === 0 ? 'bg-yellow-100' : 'bg-white'}>
                <td className="px-6 py-4 whitespace-nowrap">{formatDate(game.date)}</td>
                <td className="px-6 py-4 whitespace-nowrap">{game.bettingAmount} Birr</td>
                <td className="px-6 py-4 whitespace-nowrap">{game.players}</td>
                <td className="px-6 py-4 whitespace-nowrap">{game.calls || 0}</td>
                <td className="px-6 py-4 whitespace-nowrap">{game.status}</td>
                <td className="px-6 py-4 whitespace-nowrap">{game.bettingAmount * game.players} Birr</td>
                <td className="px-6 py-4 whitespace-nowrap">{game.bettingAmount * game.players * 0.2} Birr</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded mr-2"
        >
          Previous
        </button>
        <button
          onClick={() => paginate(currentPage + 1)}
          disabled={indexOfLastGame >= games.length}
          className="bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded"
        >
          Next
        </button>
      </div>
    </div>
  );
}

export default AdminGames;