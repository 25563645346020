import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import './GamesPage.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import BingoCard from './BingoCard';
import winSound from '../Assets/win.mp3';
import loseSound from '../Assets/lose.mp3';
import LoadingOverlay from './LoadingOverlay';
import localforage from 'localforage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const GamePage = () => {
  const location = useLocation();
  const { audioLanguage } = location.state || {};
  const gameId = location.state?.gameId;
  const calledNumbers = location.state?.calledNumbers;
  const playingCards = location.state?.playingCards;
  const callSpeed = location.state?.callSpeed;
  const payoutAmount = location.state?.payoutToWinner
  const [totalCalls, setTotalCalls] = useState(0);
  const [checkedNumber, setCheckedNumber] = useState('');
  const [lastCalledNumber, setLastCalledNumber] = useState('');
  const [intervalId, setIntervalId] = useState(null);
  const [winnerCard, setWinnerCard] = useState(null);
  const [isPaused, setIsPaused] = useState(true);
  const audioRef = useRef(null);
  const [showWinnerModal, setShowWinnerModal] = useState(false);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [slideshowTables, setSlideshowTables] = useState([]);
  const [countdown, setCountdown] = useState(callSpeed / 1000);
  const [remainingCountdown, setRemainingCountdown] = useState(callSpeed / 1000);
  const [showCardDisplay, setShowCardDisplay] = useState(false);
  const [winner, setWinner] = useState(false);
  const [calledIndex, setCalledIndex] = useState(-1);
  const [calledNumbersArray, setCalledNumbersArray] = useState([]);
  const winAudioRef = useRef(null);
  const loseAudioRef = useRef(null);
  const API = process.env.REACT_APP_API;
  const [audioFilesLoaded, setAudioFilesLoaded] = useState(false);
  const [isChecking, setIsChecking] = useState(false);
  const lastCalledNumbersRef = useRef(null);
  const [lastPausedIndex, setLastPausedIndex] = useState(-1);
  const [remainingTime, setRemainingTime] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    const fetchAndStoreAudioFiles = async () => {
      for (let i = 1; i <= 75; i++) {
        let letter = '';
        if (i >= 1 && i <= 15) {
          letter = 'B';
        } else if (i >= 16 && i <= 30) {
          letter = 'I';
        } else if (i >= 31 && i <= 45) {
          letter = 'N';
        } else if (i >= 46 && i <= 60) {
          letter = 'G';
        } else if (i >= 61 && i <= 75) {
          letter = 'O';
        }
        const number = letter + i;
        const audioKey = `audio-${audioLanguage}-${number}`;
        const storedAudioFile = await localforage.getItem(audioKey);
        if (!storedAudioFile) {
          const audioSrc = require(`../Assets/Audio/${audioLanguage}/${number}.aac`);
          const response = await fetch(audioSrc);
          const arrayBuffer = await response.arrayBuffer();
          localforage.setItem(audioKey, arrayBuffer);     
        }
      }
      setAudioFilesLoaded(true);
    };
  
    fetchAndStoreAudioFiles();
  }, [audioLanguage]);

  const scrollToLeft = () => {
    if (lastCalledNumbersRef.current) {
      lastCalledNumbersRef.current.scrollToRight = 0;
    }
  };

  useEffect(() => {
    scrollToLeft();
  }, [calledNumbersArray]);

  
  let countdownInterval;

  const startCountdown = () => {
    let remainingTime = remainingCountdown;
  
    setCountdown(remainingTime);
  
    countdownInterval = setInterval(() => {
      setRemainingCountdown((prevCountdown) => {
        const newCountdown = prevCountdown - 1;
        setCountdown(newCountdown);
        if (newCountdown <= 0) {
          clearInterval(countdownInterval);
        }
        return newCountdown;
      });
    }, 800);
  };
  
  useEffect(() => {
    let interval;
  
    const startCallingNumbers = () => {
      clearInterval(interval);
      interval = setInterval(() => {
        if (!isPaused && audioFilesLoaded) {
          setCalledIndex((prevIndex) => {
            const newIndex = prevIndex + 1;
            if (newIndex < calledNumbers.length) {
              const newLastCalledNumber = calledNumbers[newIndex];
              setLastCalledNumber(newLastCalledNumber);
              setCalledNumbersArray(calledNumbers.slice(0, newIndex + 1));
  
              localforage.getItem(`audio-${audioLanguage}-${newLastCalledNumber}`).then((arrayBuffer) => {
                const audioBlob = new Blob([arrayBuffer], { type: 'audio/aac' });
                const audioUrl = URL.createObjectURL(audioBlob);
                audioRef.current.src = audioUrl;
                audioRef.current.load();
                audioRef.current.addEventListener('canplaythrough', () => {
                  audioRef.current.play();
                });
              });
  
              setRemainingCountdown(callSpeed / 1000);
              return newIndex;
            }
            return prevIndex;
          });
        }
      }, remainingCountdown * 1000);
    };
  
    clearInterval(interval);
    clearInterval(countdownInterval);
  
    if (!isPaused && calledIndex < calledNumbers.length - 1) {
      if (remainingTime > 0) {
        // Resume from the remaining time
        timerRef.current = setTimeout(() => {
          startCallingNumbers();
          setRemainingTime(0);
        }, remainingTime);
      } else {
        startCallingNumbers();
      }
    }
  
    return () => {
      clearInterval(interval);
      clearTimeout(timerRef.current);
    };
  }, [callSpeed, calledIndex, audioLanguage, calledNumbers, isPaused, remainingTime, audioFilesLoaded]);

  useEffect(() => {
    let countdownInterval;
    if (!isPaused && countdown > 0) {
      countdownInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          }
          clearInterval(countdownInterval);
          return 0;
        });
      }, 1000);
    }
    return () => clearInterval(countdownInterval);
  }, [isPaused, countdown]);

  const handleCheckNumber = async () => {
    try {
      setIsChecking(true);
      if (!playingCards.includes(checkedNumber)) {
        toast.error('Card is not in the list of players. Please check the card number and try again.')
        setIsChecking(false)
        return;
      }
      const response = await axios.post(`${API}/game/isWinner/${gameId}/${checkedNumber}`, {
        calledNumbers: calledNumbersArray},
        {
          headers: { Authorization: `Bearer ${token}` }
      });

      const { isWinner } = response.data;

      if (isWinner) {
        setWinner(true);
        setShowCardDisplay(true);
        setShowWinnerModal(true);
        winAudioRef.current.play();

      } else{
        setWinner(false);
        setShowCardDisplay(true);
        setShowWinnerModal(true);
        loseAudioRef.current.play();
      }
    } catch (error) {
      console.error('Error checking winner:', error);
      toast.error('Error occurred while checking the winner card.');
    } finally{
      setIsChecking(false)
    }
  };

  const generateRows = (letter, start) => {
    return (
      <div className="row p-0" style={{maxHeight: 100}}>
        <div className="col text-primary text-center m-0 pb-1 px-0" style={{fontSize: 35, maxHeight: 40, maxWidth: 45}}>{letter}</div>
        {[...Array(15)].map((_, i) => {
          const number = start + i;
          const isCalled = calledNumbersArray.includes(letter + number);
          return (
            <div key={number} style={{fontSize: 25, maxHeight: 30}} className={`col m-1 p-0 ${isCalled ? 'text-white' : 'text-gray-700'} text-center`}>
              {number}
            </div>
          );
        })}
      </div>
    );
  };

  const pauseGame = () => {
    setIsPaused(true);
    setLastPausedIndex(calledIndex);
  };
  const resumeGame = () => {
    setIsPaused(false);
    if (lastPausedIndex !== -1) {
      setCalledIndex(lastPausedIndex);
    }
  };


  const endGame = async () => {
    try {  
      const response = await axios.post(
        `${API}/game/${gameId}/end`,
        { actualCalledNumbers: calledNumbersArray },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      navigate('/dashboard');
    } catch (error) {
      console.error('Error ending the game:', error);
    }
  };
  
  const winningPatterns = [
      [1, 0, 0, 0, 0, 6, 0, 0, 0, 0, 10, 0, 12, 0, 0, 15, 0, 0, 0, 0, 21, 0, 0, 0, 0],
      [0, 2, 0, 0, 0, 0, 7, 0, 0, 0, 0, 11, 12, 0, 0, 0, 17, 0, 0, 0, 0, 22, 0, 0, 0],
      [0, 0, 3, 0, 0, 0, 0, 8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 18, 0, 0, 0, 0, 23, 0, 0],
      [0, 0, 0, 4, 0, 0, 0, 0, 9, 0, 0, 0, 12, 13, 0, 0, 0, 0, 19, 0, 0, 0, 0, 24, 0],
      [0, 0, 0, 0, 5, 0, 0, 0, 0, 10, 0, 0, 12, 0, 14, 0, 0, 0, 0, 20, 0, 0, 0, 0, 25],
      
      [1, 2, 3, 4, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 6, 7, 8, 9, 10, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 11, 13, 12, 14, 15, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 16, 17, 18, 19, 20, 0, 0, 0, 0, 0],
      [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 22, 23, 24, 25],
  
      [1, 0, 0, 0, 0, 0, 7, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 19, 0, 0, 0, 0, 0, 25],
      [0, 0, 0, 0, 5, 0, 0, 0, 10, 0, 0, 0, 12, 0, 0, 0, 17, 0, 0, 0, 21, 0, 0, 0, 0],
  
      [1, 0, 0, 0, 5, 0, 0, 0, 0, 0, 0, 0, 12, 0, 0, 0, 0, 0, 0, 0, 21, 0, 0, 0, 25],
      [0, 0, 0, 0, 0, 0, 7, 0, 9, 0, 0, 0, 12, 0, 0, 0, 16, 0, 18, 0, 0, 0, 0, 0, 0],
    ];

  useEffect(() => {
      const tables = winningPatterns.map((pattern) => generateTable(pattern));
      setSlideshowTables(tables);
    }, []);
    
    
    const generateTable = (pattern) => {
      const cellSize = 25;
      const dotSize = 25;
      const cells = pattern.map((value, index) => (
        <td
          key={index}
          className="relative py-3 px-4 w-3 h-3 bg-gray-200 border border-black"
        >
          <div
            className={`absolute inset-0 m-auto rounded-full ${
              value === 12
                ? 'bg-yellow-400 animate-pulse'
                : value > 0
                ? 'bg-blue-700'
                : 'bg-transparent'
            }`}
            style={{ width: `${dotSize}px`, height: `${dotSize}px` }}
          ></div>
        </td>
      ));
    
      const rows = [];
      for (let i = 0; i < 5; i++) {
        rows.push(
          <tr key={i} className="grid grid-cols-5">
            {cells.slice(i * 5, (i + 1) * 5)}
          </tr>
        );
      }
    
      const headerCells = ['B', 'I', 'N', 'G', 'O'].map((letter, index) => (
        <th
          key={index}
          className={`font-bold text-center text-white ${
            ['bg-blue-500', 'bg-red-500', 'bg-orange-500', 'bg-green-500', 'bg-yellow-400'][index]
          } w-15 h-10 text-2xl border border-white`}
        >
          {letter}
        </th>
      ));
    
      return (
        <table className="mb-0 pb-0 bg-gray-200">
          <thead>
            <tr className="grid grid-cols-5">{headerCells}</tr>
          </thead>
          <tbody className="mb-0 pb-0 bg-gray-200">{rows}</tbody>
        </table>
      );
    };
    
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2000,
      cssEase: 'linear',
      arrows: false,
    };

  const handleCloseWinnerModal = () => {
        setShowWinnerModal(false);
        setShowCardDisplay(false);
      };

      return (
        <div className="min-h-screen bg-gray-900 text-white p-4">
          {!audioFilesLoaded && <LoadingOverlay />}
          <ToastContainer/>
          <audio ref={winAudioRef} src={winSound} />
          <audio ref={loseAudioRef} src={loseSound} />
          <Modal
            show={showWinnerModal}
            onHide={handleCloseWinnerModal}
            size="xl"
            className="winner-modal"
          >
            <Modal.Body className="bg-gray-800">
              {showCardDisplay && (
                <BingoCard
                  cardNumber={checkedNumber}
                  calledNumbers={calledNumbersArray}
                  isWinner={winner}
                />
              )}
            </Modal.Body>
            <Modal.Footer className="bg-gray-800">
              <button
                className="px-4 py-2 bg-gray-700 text-white rounded-md"
                onClick={handleCloseWinnerModal}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
          <div className="grid grid-cols-5 gap-4">
            <div className="col-span-1">
              <h3 className="text-2xl font-bold text-center mb-1">Total Calls</h3>
              <h1 className="text-4xl text-center bg-blue-900 py-2 rounded-md">
                {calledNumbersArray.length}
              </h1>
              <div className="mt-4">
                <Slider {...settings}>
                  {slideshowTables.map((table, index) => (
                    <div key={index}>{table}</div>
                  ))}
                </Slider>
              </div>
              <div className="mt-2">
                <h6 className="text-lg text-center mb-1">Next Number</h6>
                <h3 className="text-6xl text-center countdown-animation">{countdown}</h3>
              </div>
              <div className="flex justify-center mt-2">
                {isPaused ? (
                  <button
                    className="px-6 py-1 bg-blue-500 text-white rounded-md text-lg"
                    onClick={resumeGame}
                  >
                    Resume
                  </button>
                ) : (
                  <button
                    className="px-6 py-1 bg-yellow-500 text-white rounded-md text-lg"
                    onClick={pauseGame}
                  >
                    Pause
                  </button>
                )}
              </div>
              <div className="flex mt-2">
                <input
                  type="text"
                  value={checkedNumber}
                  onChange={(e) => setCheckedNumber(e.target.value)}
                  placeholder="Number"
                  className="flex-1 ml-3 px-3 py-1 bg-gray-800 rounded-l-md focus:outline-none"
                  style={{maxWidth: '50%'}}
                />
                <button
                  className="px-3 py-2 bg-yellow-500 text-white rounded-r-md"
                  onClick={handleCheckNumber}
                >
                  Check
                </button>
              </div>
              <div className="mt-3 text-center">
                <button
                  className="px-6 py-2 bg-red-500 text-white rounded-md w-1/2"
                  onClick={endGame}
                >
                  End game
                </button>
              </div>
            </div>
            <div className="col-span-4">
              <div className="bg-gray-800 rounded-md p-4">
                {generateRows('B', 1)}
                {generateRows('I', 16)}
                {generateRows('N', 31)}
                {generateRows('G', 46)}
                {generateRows('O', 61)}
              </div>
              <div className="mt-4">
                <div className="text-right text-4xl">
                  ደራሽ{' '}
                  <span className="bg-green-500 text-white px-4 py-2 rounded-md">
                    {payoutAmount}
                  </span>{' '}
                  Birr
                </div>
                <div className="mt-4 last-called-numbers-container " ref={lastCalledNumbersRef}>
                  {calledNumbersArray.slice(-10).map((number, index) => (
                    <div
                      key={index}
                      className={`${
                        number.includes('I')
                          ? 'border-red-500'
                          : number.includes('N')
                          ? 'border-orange-500'
                          : number.includes('G')
                          ? 'border-green-500'
                          : 'border-yellow-500'
                      } ${
                        number === calledNumbersArray[calledNumbersArray.length - 1]
                          ? 'last-called-number-circle animate-scale-in'
                          : 'last-five-numbers'
                      } flex items-center justify-center text-4xl font-bold m-2 shrink-0`}
                    >
                      {number}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <audio ref={audioRef} />
        </div>
      );
    };
    

export default GamePage;