import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import LoadingOverlay from './LoadingOverlay';

const AdminTable = () => {
  const API = process.env.REACT_APP_API;
  const [condensedView, setCondensedView] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [editingAdminId, setEditingAdminId] = useState(null);
  const [addAmount, setAddAmount] = useState('');
  const [showRegistration, setShowRegistration] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [initialWallet, setInitialWallet] = useState('');
  const [lastFiftyGames, setLastFiftyGames] = useState([]);
  const [showLastFiftyGames, setShowLastFiftyGames] = useState(false);
  const [lastTenGamesUsername, setLastTenGamesUsername] = useState('');
  const [showLast30DaysProfits, setShowLast30DaysProfits] = useState(false);
  const [last30DaysProfits, setLast30DaysProfits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState('');
  const [editingAdmin, setEditingAdmin] = useState(null);
  const [blockedIPs, setBlockedIPs] = useState([]);
  const [showBlockedIPsModal, setShowBlockedIPsModal] = useState(false);
  const [lastRefreshTime, setLastRefreshTime] = useState(null);
  const [ipToBlock, setIPToBlock] = useState('');
  const [highestNumberOfPlayersData, setHighestNumberOfPlayersData] = useState([]);
  const [adminData, setAdminData] = useState([]);
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [adminLastGameStartTimes, setAdminLastGameStartTimes] = useState({});
  const [topNumberOfPlayers, setTopNumberOfPlayers] = useState(0);
  const [topProfit, setTopProfit] = useState(0);
  const [gamesPerHourByDate, setGamesPerHourByDate] = useState({});
  const [showGamesPerHourModal, setShowGamesPerHourModal] = useState(false);
  const [todaysTotalGames, setTodaysTotalGames] = useState(0);
  const [highestGamesOfADay, setHighestGamesOfADay] = useState({ date: '', totalGames: 0 });
  const [gamesPerDay, setGamesPerDay] = useState({});
  const [showGamesPerDayModal, setShowGamesPerDayModal] = useState(false);
  const [totalGamesToDate, setTotalGamesToDate] = useState(0);

  const [highestNumberOfPlayers, setHighestNumberOfPlayers] = useState({
    today: 0,
    week: 0,
    month: 0,
    allTime: 0,
  });

  const fetchGamesPerHourByDate = async () => {
    try {
      const response = await axios.get(`${API}/admin/games-per-hour-by-date`);
      setGamesPerHourByDate(response.data);
      setShowGamesPerHourModal(true);
    } catch (error) {
      console.error('Error fetching games per hour by date:', error);
    }
  };

  const fetchBlockedIPs = async () => {
    try {
      const response = await axios.get(`${API}/ip/blocked`);
      setBlockedIPs(response.data);
      setShowBlockedIPsModal(true);
    } catch (error) {
      console.error('Error fetching blocked IPs:', error);
    }
  };

  const handleBlockIP = async () => {
    try {
      await axios.put(`${API}/ip/block/${ipToBlock}`);
      setIPToBlock('');
      fetchBlockedIPs();
    } catch (error) {
      console.error('Error blocking IP:', error);
    }
  };


  const fetchHighestNumberOfPlayers = async () => {
    try {
      const response = await axios.get(`${API}/admin/highestPlayers`);
      setHighestNumberOfPlayersData(response.data);

      const topPlayers = Math.max(...response.data.map((data) => data.allTime));
      setTopNumberOfPlayers(topPlayers);

      const topProfitValue = Math.max(...response.data.map((data) => data.highestProfitAllTime));
      setTopProfit(topProfitValue);
    } catch (error) {
      console.error('Error fetching highest number of players:', error);
    }
  };

  const handleUnblockIP = async (ip) => {
    try {
      await axios.put(`${API}/ip/unblock/${ip}`);
      fetchBlockedIPs();
    } catch (error) {
      console.error('Error unblocking IP:', error);
    }
  };

  const fetchAdminLastGameStartTime = async (adminId) => {
    try {
      const response = await axios.get(`${API}/admin/lastGame/${adminId}`);
      setAdminLastGameStartTimes((prevTimes) => ({
        ...prevTimes,
        [adminId]: response.data.lastGameStartTime,
      }));
    } catch (error) {
      console.error('Error fetching admin last game start time:', error);
    }
  };
  
  const fetchGamesData = async () => {
    try {
      const response = await axios.get(`${API}/admin/games-data`);
      const { todaysTotalGames, highestGamesOfADay, gamesPerDay, totalGamesToDate } = response.data;
      setTodaysTotalGames(todaysTotalGames);
      setHighestGamesOfADay(highestGamesOfADay);
      setGamesPerDay(gamesPerDay);
      setTotalGamesToDate(totalGamesToDate);
    } catch (error) {
      console.error('Error fetching games data:', error);
    }
  };

  useEffect(() => {
    admins.forEach((admin) => {
      fetchAdminLastGameStartTime(admin._id);
    });
  }, [admins]);

  useEffect(() => {
    const fetchAverageTimeBetweenGames = async () => {
      try {
        const promises = admins.map((admin) =>
          axios.get(`${API}/admin/average-time-between-games/${admin._id}`)
        );
        const responses = await Promise.all(promises);
        const data = responses.map((response) => ({
          adminId: response.config.url.split('/').pop(),
          averageTimeBetweenGames: response.data.averageTimeBetweenGames,
        }));
        setAdminData(data);
      } catch (error) {
        console.error('Error fetching average time between games:', error);
      }
    };

    fetchAverageTimeBetweenGames();
    fetchGamesData()
  }, [admins]);

  useEffect(() => {
    const checkAuthorization = () => {
      const expirationTime = localStorage.getItem('authExpiration');
      if (expirationTime && new Date().getTime() < parseInt(expirationTime)) {
        setAuthorized(true);
        fetchAdmins();
      } else {
        localStorage.removeItem('authExpiration');
        setAuthorized(false);
        setShowPasswordPrompt(true);
      }
    };

    checkAuthorization();
    const interval = setInterval(checkAuthorization, 60 * 1000); // Check every minute

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
  
    return () => {
      clearInterval(interval);
    };
  }, []);

  const fetchAdminGames = async (adminId) => {
    try {
      const response = await axios.get(`${API}/admin/myGames`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching admin games:', error);
      return [];
    }
  };
  
  const calculateAverageTimeBetweenGames = (games) => {
    if (games.length < 2) {
      return '-';
    }
  
    const timeDifferences = [];
  
    for (let i = 1; i < games.length; i++) {
      const currentGame = new Date(games[i].date);
      const previousGame = new Date(games[i - 1].date);
      const timeDifference = (currentGame - previousGame) / 1000; // Convert to seconds
      timeDifferences.push(timeDifference);
    }
  
    const averageTimeDifference =
      timeDifferences.reduce((sum, diff) => sum + diff, 0) / timeDifferences.length;
  
    // Convert seconds to minutes and round to 2 decimal places
    const averageMinutes = (averageTimeDifference / 60).toFixed(2);
  
    return averageMinutes;
  };

  const handlePasswordSubmit = (e) => {
    e.preventDefault();
    if (enteredPassword === '2256') {
      const expirationTime = new Date().getTime() + 5 * 60 * 1000; // 5 minutes from now
      localStorage.setItem('authExpiration', expirationTime);
      setAuthorized(true);
      setShowPasswordPrompt(false);
      fetchAdmins();
    } else {
      alert('Invalid password');
    }
  };

  const handleRefresh = () => {
    fetchGamesData()
    setIsLoading(true);
    fetchAdmins().finally(() => {
      setIsLoading(false);
      setLastRefreshTime(new Date());
    });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const options = {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: false,
    };
    const formattedTimestamp = new Date(timestamp).toLocaleString('en-US', options);
    const timePassed = currentTime - timestamp;
    const seconds = Math.floor(timePassed / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    let timeSinceRefresh = '';
    if (days > 0) {
      timeSinceRefresh = `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      timeSinceRefresh = `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      timeSinceRefresh = `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      timeSinceRefresh = `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
    }
    return `${formattedTimestamp} (${timeSinceRefresh})`;
  };
  useEffect(() => {
    fetchHighestNumberOfPlayers();
  }, [admins]);

  const [lastTenGames, setLastTenGames] = useState([]);

  const fetchLast30DaysProfits = () => {
    setIsLoading(true);
    const today = new Date();
    const last30Days = new Date(today);
    last30Days.setDate(today.getDate() - 30);

    const last30DaysProfits = [];

    for (let date = last30Days; date <= today; date.setDate(date.getDate() + 1)) {
      const dateString = date.toISOString().slice(0, 10);
      const dailyProfit = admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((total, admin) => {
        const dailyStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(dateString));
        return total + (dailyStat ? dailyStat.profit : 0);
      }, 0);
      last30DaysProfits.push({ date: dateString, profit: Math.round(dailyProfit * 0.175) });
    }

    setLast30DaysProfits(last30DaysProfits.reverse());
    setShowLast30DaysProfits(true);
    setIsLoading(false)
  };

  const fetchLastTenGames = async (adminId, adminUsername) => {
    try {
      setIsLoading(true)
      const response = await axios.get(`${API}/admin/${adminId}/last-games`);
      setLastTenGames(response.data);
      setLastTenGamesUsername(adminUsername);
    } catch (error) {
      console.error('Error fetching last 10 games:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const fetchLastFiftyGames = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(`${API}/admin/last-games`);
      setLastFiftyGames(response.data);
      setShowLastFiftyGames(true);
    } catch (error) {
      console.error('Error fetching last 50 games:', error);
    } finally {
      setIsLoading(false)
    }
  };

  const handleRegistration = async (event) => {
    setIsLoading(true)
    event.preventDefault();
    try {
      await axios.post(`${API}/admin/signup/protected/naan`, { username, password, initialWallet });
      alert('User registered successfully');
      setShowRegistration(false);
      setUsername('');
      setPassword('');
      setInitialWallet('');
    } catch (error) {
      console.error('Error registering user:', error);
      alert('Failed to register user');
    } finally {
      setIsLoading(false)
    }
  };

  const getYesterdaysProfit = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const yesterdayString = yesterday.toISOString().slice(0, 10);

    const yesterdayStats = admins
      .filter((admin) => admin.username !== '0923125171')
      .flatMap((admin) =>
        admin.statsByPeriod.filter((stat) => stat.date.startsWith(yesterdayString))
      );

    const totalProfit = yesterdayStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit * 0.175;
  };


  const fetchAdmins = async () => {
    try {
      const response = await axios.get(`${API}/admin/naan/all/protected`);
      setAdmins(response.data);
      setLastRefreshTime(new Date());
    } catch (error) {
      console.error('Error fetching admins:', error);
    }
  };

  const handleEditWallet = (adminId) => {
    setEditingAdminId(adminId);
    setAddAmount('');
  };

  const handleAddAmountChange = (event) => {
    setAddAmount(event.target.value);
  };

  const addToWallet = async (adminId) => {
    try {
      const currentAdmin = admins.find((admin) => admin._id === adminId);
      const updatedWallet = currentAdmin.wallet + parseFloat(addAmount);
      await axios.put(`${API}/admin/${adminId}/wallet`, { wallet: updatedWallet });
      setAdmins(
        admins.map((admin) => (admin._id === adminId ? { ...admin, wallet: updatedWallet } : admin))
      );
      setEditingAdminId(null);
      setAddAmount('');
    } catch (error) {
      console.error('Error updating admin wallet:', error);
    }
  };


  const getTodaysProfit = (admin) => {
    const today = new Date().toISOString().slice(0, 10);
    const todayStat = admin.statsByPeriod.find((stat) => stat.date.startsWith(today));
    return todayStat ? todayStat.profit : 0;
  };

  const getThisWeekAverage = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    const averageProfit = totalProfit / thisWeekStats.length;

    return isNaN(averageProfit) ? 0 : averageProfit.toFixed(2);
  };

  const getThisWeekProfit = (admin) => {
    const today = new Date();
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(today.setDate(today.getDate() - today.getDay() + 6));

    const thisWeekStats = admin.statsByPeriod.filter(
      (stat) => new Date(stat.date) >= startOfWeek && new Date(stat.date) <= endOfWeek
    );

    const totalProfit = thisWeekStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };


  const getMaxProfit = (admin) => {
    const maxProfit = Math.max(...admin.statsByPeriod.map((stat) => stat.profit));
    return isFinite(maxProfit) ? maxProfit : 0;
  };

  const getAverageDailyProfit = (admin) => {
    const profitableDays = admin.statsByPeriod.filter((stat) => stat.profit > 0);
    const totalProfit = profitableDays.reduce((sum, stat) => sum + stat.profit, 0);
    const averageDailyProfit = totalProfit / profitableDays.length;
    return isNaN(averageDailyProfit) ? 0 : averageDailyProfit.toFixed(2);
  };

  const getTodaysTotalProfit = () => {
    return admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((total, admin) => total + getTodaysProfit(admin), 0);
  };

  const getThisWeekTotalProfit = () => {
    return admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((total, admin) => total + getThisWeekProfit(admin), 0);
  };

  const getMonthlyProfit = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const monthlyStats = admins
      .filter((admin) => admin.username !== '0923125171')
      .flatMap((admin) =>
        admin.statsByPeriod.filter((stat) => {
          const statDate = new Date(stat.date);
          return statDate.getMonth() === currentMonth && statDate.getFullYear() === currentYear;
        })
      );

    const totalProfit = monthlyStats.reduce((sum, stat) => sum + stat.profit, 0);
    return totalProfit;
  };

  const getTotalProfitToDate = () => {
    const totalProfit = admins
      .filter((admin) => admin.username !== '0923125171')
      .reduce((sum, admin) => sum + admin.totalProfit, 0);
    return totalProfit;
  };

  const handleBlockUnblockAdmin = async (adminId, isBlocked) => {
    try {
      const url = isBlocked ? `${API}/admin/unblock/${adminId}` : `${API}/admin/block/${adminId}`;
      await axios.put(url);
      setAdmins(
        admins.map((admin) =>
          admin._id === adminId ? { ...admin, isBlocked: !isBlocked } : admin
        )
      );
    } catch (error) {
      console.error('Error blocking/unblocking admin:', error);
    }
  };

  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const sortAdmins = (admins, key, direction) => {
    return admins.sort((a, b) => {
      let valueA, valueB;

      if (key === 'lastLoginTime') {
        valueA = a.lastLoginTime ? new Date(a.lastLoginTime) : 0;
        valueB = b.lastLoginTime ? new Date(b.lastLoginTime) : 0;
      } else if (key === 'todaysProfit') {
        valueA = getTodaysProfit(a);
        valueB = getTodaysProfit(b);
      } else if (key === 'thisWeekAverage') {
        valueA = parseFloat(getThisWeekAverage(a));
        valueB = parseFloat(getThisWeekAverage(b));
      } else if (key === 'maxProfit') {
        valueA = getMaxProfit(a);
        valueB = getMaxProfit(b);
      } else {
        valueA = a[key];
        valueB = b[key];
      }

      if (valueA < valueB) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (valueA > valueB) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });
  };

  const EditAdminModal = ({ admin, show, onClose, onSave }) => {
    const [username, setUsername] = useState(admin?.username || '');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
  
    const handleSave = () => {
      onSave({ ...admin, username, currentPassword, newPassword });
      onClose();
    };
  
    return (
      <Modal show={show} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="currentPassword">
              <Form.Label>Current Password</Form.Label>
              <Form.Control
                type="password"
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </Form.Group>
            <Form.Group controlId="newPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleEditAdmin = async (updatedAdmin) => {
    try {
      await axios.put(`${API}/admin/edit-username-and-password/${updatedAdmin._id}`, {
        username: updatedAdmin.username,
        currentPassword: updatedAdmin.currentPassword,
        newPassword: updatedAdmin.newPassword,
      });
      setAdmins(
        admins.map((admin) =>
          admin._id === updatedAdmin._id
            ? { ...admin, username: updatedAdmin.username }
            : admin
        )
      );
      alert('Success');
    } catch (error) {
      console.error('Error updating admin:', error);
      if (error.response.status === 401) {
        alert('Invalid current password');
      } else if (error.response.status === 400) {
        alert('Username already exists');
      } else {
        alert('Failed to update admin');
      }
    }
  };

  const sortedAdmins = sortConfig.key
    ? sortAdmins(admins, sortConfig.key, sortConfig.direction)
    : admins;

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const getLastWalletEditAmount = (admin) => {
    const lastEdit = admin.walletEditHistory[admin.walletEditHistory.length - 1];
    return lastEdit ? lastEdit.amount : '-';
  };
  
  const getLastWalletEditDate = (admin) => {
    const lastEdit = admin.walletEditHistory[admin.walletEditHistory.length - 1];
    return lastEdit ? new Date(lastEdit.date).toLocaleDateString() : '-';
  };

  const getArrowDirection = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  const getAverageDaysBetweenEdits = (admin) => {
    const edits = admin.walletEditHistory;
    if (edits.length < 2) {
      return '-';
    }
  
    const totalDays = edits.reduce((total, edit, index) => {
      if (index === 0) {
        return total;
      }
      const currentDate = new Date(edit.date);
      const previousDate = new Date(edits[index - 1].date);
      const daysDiff = (currentDate - previousDate) / (1000 * 60 * 60 * 24);
      return total + daysDiff;
    }, 0);
  
    const averageDays = totalDays / (edits.length - 1);
    return averageDays.toFixed(2);
  };
  
  const getAverageCreditAmount = (admin) => {
    const edits = admin.walletEditHistory;
    if (edits.length === 0) {
      return '-';
    }
  
    const totalAmount = edits.reduce((total, edit) => total + edit.amount, 0);
    const averageAmount = totalAmount / edits.length;
    return averageAmount.toFixed(2);
  };

  return (
    <div className="mx-5 mt-5">
      {isLoading && <LoadingOverlay />}
      <Modal show={showBlockedIPsModal} onHide={() => setShowBlockedIPsModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Blocked IPs</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>IP Address</th>
              <th>Hit Count</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {blockedIPs.map((blockedIP) => (
              <tr key={blockedIP.ip}>
                <td>{blockedIP.ip}</td>
                <td>
                  <details>
                    <summary className="cursor-pointer">View Hit Counts</summary>
                    <table className="table table-bordered mt-2">
                      <thead>
                        <tr>
                          <th>Hour</th>
                          <th>Minute</th>
                          <th>Day</th>
                          <th>Week</th>
                          <th>Month</th>
                          <th>All Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{blockedIP.hitCount.hour}</td>
                          <td>{blockedIP.hitCount.minute}</td>
                          <td>{blockedIP.hitCount.day}</td>
                          <td>{blockedIP.hitCount.week}</td>
                          <td>{blockedIP.hitCount.month}</td>
                          <td>{blockedIP.hitCount.allTime}</td>
                        </tr>
                      </tbody>
                    </table>
                  </details>
                </td>
                <td>
                  <Button
                    onClick={() => handleUnblockIP(blockedIP.ip)}
                    variant="success"
                    size="sm"
                    className="mr-2"
                  >
                    Unblock
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="mt-4">
          <input
            type="text"
            value={ipToBlock}
            onChange={(e) => setIPToBlock(e.target.value)}
            placeholder="Enter IP to block"
            className="form-control mb-2"
          />
          <Button onClick={handleBlockIP} variant="danger" size="sm">
            Block IP
          </Button>
        </div>
      </Modal.Body>
      </Modal>
      <EditAdminModal
        admin={editingAdmin}
        show={editingAdmin !== null}
        onClose={() => setEditingAdmin(null)}
        onSave={handleEditAdmin}
      />
      {showPasswordPrompt && (
        <Modal show={showPasswordPrompt} onHide={() => setShowPasswordPrompt(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Password Required</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handlePasswordSubmit}>
              <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={enteredPassword}
                  onChange={(e) => setEnteredPassword(e.target.value)}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </Form.Group>
              <Button type="submit" className="mt-4 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Submit
              </Button>
            </Form>
          </Modal.Body>
        </Modal>
      )}
      {authorized && (
        <div>
          {lastRefreshTime && (
            <span className="ml-4 text-sm text-gray-500">
              Last refreshed: {formatTimestamp(lastRefreshTime)}
            </span>
          )}
          <div className='flex items-center mb-4'>
            <h2 className="text-2xl font-semibold">Admin Dashboard</h2>
            <button
              onClick={() => setCondensedView(!condensedView)}
              className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {condensedView ? 'Show Detailed View' : 'Show Condensed View'}
            </button>
            <Button onClick={handleRefresh} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" disabled={isLoading}>
              {isLoading ? 'Refreshing...' : 'Refresh Data'}
            </Button>
            <Button onClick={() => setShowRegistration(true)} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Register User
            </Button>
            <Button onClick={fetchLastFiftyGames} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Last 50 Games
            </Button>
            <Button onClick={fetchLast30DaysProfits} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500">
              Last 30 Days Profits
            </Button>
            <Button onClick={() => fetchBlockedIPs()} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500">
              Blocked IPs
            </Button>
            <Button onClick={fetchGamesPerHourByDate} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Games Per Hour
            </Button>
            <Button onClick={() => setShowGamesPerDayModal(true)} className="ml-4 px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Games Per Day
            </Button>
          </div>
          <Modal show={showGamesPerDayModal} onHide={() => setShowGamesPerDayModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Games Per Day</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Total Games</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(gamesPerDay)
                    .sort((a, b) => b[0].localeCompare(a[0])) // Sort dates in descending order
                    .map(([date, totalGames]) => (
                      <tr key={date}>
                        <td>{date}</td>
                        <td>{totalGames}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>

      <Modal show={showLast30DaysProfits} onHide={() => setShowLast30DaysProfits(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Profits of Last 30 Days</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Date</th>
                <th>Profit</th>
              </tr>
            </thead>
            <tbody>
              {last30DaysProfits.map((entry) => (
                <tr key={entry.date}>
                  <td>{entry.date}</td>
                  <td>{entry.profit}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={showLastFiftyGames} onHide={() => setShowLastFiftyGames(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Last 50 Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Admin</th>
                <th>Betting Amount</th>
                <th>Number of Players</th>
                <th>Winning Amount</th>
                <th>Profit</th>
                <th> Status</th>
              </tr>
            </thead>
            <tbody>
              {lastFiftyGames.map((game) => (
                <tr key={game._id}>
                  <td>{new Date(game.createdAt).toLocaleDateString('en-US', {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  })} {new Date(game.createdAt).toLocaleTimeString('en-US', {
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit'
                  })}</td>
                  <td>
                    {game.endedAt ? (
                      new Date(game.endedAt).toLocaleTimeString('en-US', {
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit',
                      })
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>{game.admin.username}</td>
                  <td>{game.bettingAmount}</td>
                  <td>{game.numberOfPlayers}</td>
                  <td>{game.payoutToWinner}</td>
                  <td>{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                  <td>{game.status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal.Body>
      </Modal>

      <Modal show={showRegistration} onHide={() => setShowRegistration(false)}>
        <Modal.Header closeButton>
          <Modal.Title>User Registration</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleRegistration}>
            <Form.Group controlId="username">
              <Form.Label>Username</Form.Label>
              <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="password">
              <Form.Label>Password</Form.Label>
              <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
            </Form.Group>
            <Form.Group controlId="initialWallet">
              <Form.Label>Initial Wallet</Form.Label>
              <Form.Control type="number" value={initialWallet} onChange={(e) => setInitialWallet(e.target.value)} required />
            </Form.Group>
            <Button variant="primary" type="submit" className="mt-3">
              Register
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showGamesPerHourModal} onHide={() => setShowGamesPerHourModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Games Per Hour By Date</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {Object.entries(gamesPerHourByDate)
            .sort((a, b) => b[0].localeCompare(a[0])) // Sort dates in descending order
            .map(([date, gamesPerHour]) => {
              const highestWorkHour = gamesPerHour.reduce((maxHour, entry) =>
                entry.totalGames > maxHour.totalGames ? entry : maxHour
              );
              const adjustedHighestHour = (highestWorkHour.hour + 3) % 24; // Adjust hour for GMT+3 timezone
              const formattedHighestHour = `${adjustedHighestHour.toString().padStart(2, '0')}:00`; // Format hour as HH:00

              return (
                <div key={date}>
                  <h4>
                    {date} (Max Hour: {formattedHighestHour} - {highestWorkHour.totalGames} games)
                  </h4>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Hour</th>
                        <th>Total Games</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gamesPerHour.map((entry) => {
                        const adjustedHour = (entry.hour + 3) % 24; // Adjust hour for GMT+3 timezone
                        const formattedHour = `${adjustedHour.toString().padStart(2, '0')}:00`; // Format hour as HH:00
                        return (
                          entry.totalGames > 0 && (
                            <tr key={`${date}-${entry.hour}`}>
                              <td>{formattedHour}</td>
                              <td>{entry.totalGames}</td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              );
            })}
        </Modal.Body>
      </Modal>

          <div className="mb-4">
            <button className="px-4 py-2 mr-2 text-white bg-yellow-500 rounded-md">Today's Profit: {getTodaysTotalProfit() * 0.175}</button>
            <button className="px-4 py-2 mr-2 text-white bg-green-500 rounded-md">
              Today's Total Games: {todaysTotalGames}
            </button>
            <button className="px-4 py-2 mr-2 text-white bg-green-500 rounded-md">Yesterday's Profit: {Math.round(getYesterdaysProfit())}</button>
            <button className="px-4 py-2 mr-2 text-white bg-green-500 rounded-md">This Week's Total Profit: {Math.round(getThisWeekTotalProfit() * 0.175)}</button>
            <button className="px-4 py-2 text-white bg-green-500 rounded-md">This month's Profit: {Math.round(getMonthlyProfit() * 0.175)}</button>
            <button className="px-4 py-2 ml-2 text-white bg-green-500 rounded-md">Total Profit to Date: {Math.round(getTotalProfitToDate() * 0.175)}</button>
          </div>
          <div className="mb-4 flex">
            <button className="px-4 py-2 mr-2 text-white bg-gray-800 rounded-md">Today's Total Revenue: {Math.round(getTodaysTotalProfit())}</button>
            <button className="px-4 py-2 mr-2 text-white bg-gray-800 rounded-md">This Week's Total Revenue: {Math.round(getThisWeekTotalProfit())}</button>
            <button className="px-4 py-2 text-white bg-gray-800 rounded-md">Monthly Revenue: {Math.round(getMonthlyProfit())}</button>
            <button className="px-4 py-2 ml-2 mr-2 text-white bg-gray-800 rounded-md">Total Revenue to Date: {Math.round(getTotalProfitToDate())}</button>
            <button className="px-4 py-2 text-white bg-gray-800 rounded-md">
              Total Games to Date: {totalGamesToDate}
            </button>
          </div>
          <div className="mb-4 flex">
            <button className="px-4 py-2 mr-2 text-white bg-purple-600 rounded-md">
              Top Number of Players Today: {topNumberOfPlayers} 
            </button>
            <button className="px-4 py-2 mr-2 text-white bg-purple-600 rounded-md">
              Top Profit Today (1 game): {topProfit} Birr
            </button>
            <button className="px-4 py-2 text-white bg-blue-600 rounded-md">
              Highest Games in a Day: {highestGamesOfADay.totalGames} ({highestGamesOfADay.date})
            </button>
          </div>

          {condensedView ? (
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Username</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total Profit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Wallet</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Today's Profit</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Games</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Block/Unblock</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {admins.filter((admin) => !admin.username.endsWith('-')).map((admin) => (
              <tr key={admin._id}>
                <td className="px-6 py-4 whitespace-nowrap">
                  {admin.username}
                  <button
                    onClick={() => setEditingAdmin(admin)}
                    className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                    </svg>
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">{admin.totalProfit}</td>
                <td className="px-6 py-4 whitespace-nowrap">{admin.wallet}</td>
                <td className="px-6 py-4 whitespace-nowrap">{getTodaysProfit(admin)}</td>
                <td className="px-6 py-4 whitespace-nowrap">
                  {editingAdminId === admin._id ? (
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <input
                        type="number"
                        value={addAmount}
                        onChange={handleAddAmountChange}
                        className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                        placeholder="Add amount"
                      />
                      <button
                        onClick={() => addToWallet(admin._id)}
                        className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-indigo-600 text-white text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Add
                      </button>
                      <button
                        onClick={() => setEditingAdminId(null)}
                        className="ml-2 inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-200 text-gray-700 text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <button
                      onClick={() => handleEditWallet(admin._id)}
                      className="px-2 py-1 text-sm font-medium text-white bg-yellow-500 rounded-md"
                    >
                      Edit
                    </button>
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => fetchLastTenGames(admin._id, admin.username)}
                    className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded-md"
                  >
                    Last 10 Games
                  </button>
                </td>
                <td className="px-6 py-4 whitespace-nowrap">
                  <button
                    onClick={() => handleBlockUnblockAdmin(admin._id, admin.isBlocked)}
                    className={`px-2 py-1 text-sm font-medium rounded-md ${
                      admin.isBlocked
                        ? 'text-white bg-green-500 hover:bg-green-600'
                        : 'text-white bg-red-500 hover:bg-red-600'
                    }`}
                  >
                    {admin.isBlocked ? 'Unblock' : 'Block'}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('username')}>
                  Username {getArrowDirection('username')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('lastLoginTime')}>
                  Last Login {getArrowDirection('lastLoginTime')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Game Start Time
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Login IP
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('totalProfit')}>
                  Total Profit {getArrowDirection('totalProfit')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('wallet')}>
                  Wallet {getArrowDirection('wallet')}
                </th>                
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stats by Period</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('todaysProfit')}>
                  Today's Profit {getArrowDirection('todaysProfit')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('thisWeekAverage')}>
                  This Week's Average {getArrowDirection('thisWeekAverage')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Average Daily Profit (All Time)
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" onClick={() => requestSort('maxProfit')}>
                  Max Profit to Date {getArrowDirection('maxProfit')}
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Games</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Wallet Edit Amount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Last Wallet Edit Date</th>             
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Days Between Wallet Edits</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Credit Amount</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Wallet Edit History</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Time Between Games (Today)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Time Between Games (Week)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Time Between Games (Month)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Average Time Between Games (All Time)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Players (Today)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Players (Week)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Players (Month)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Players (All Time)</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Profit Game Today</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Highest Profit Game All Time</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Block/Unblock</th>
  
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {admins.filter((admin) => !admin.username.endsWith('-')).map((admin) => (
                <tr key={admin._id}>
                  <td className="px-6 py-4 whitespace-nowrap">{admin.username}  <button
                      onClick={() => setEditingAdmin(admin)}
                      className="ml-2 text-blue-500 hover:text-blue-700 focus:outline-none"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {admin.lastLoginTime ? (
                      <>
                        {new Date(admin.lastLoginTime).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}{' '}
                        {new Date(admin.lastLoginTime).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })}
                      </>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                  {adminLastGameStartTimes[admin._id] ? (
                    new Date(adminLastGameStartTimes[admin._id]).toDateString() ===
                    new Date().toDateString() ? (
                      new Date(adminLastGameStartTimes[admin._id]).toLocaleTimeString()
                    ) : (
                      new Date(adminLastGameStartTimes[admin._id]).toLocaleString()
                    )
                  ) : (
                    '-'
                  )}
                </td>
                  <td className="px-6 py-4 whitespace-nowrap">{admin.lastLoginIP}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{admin.totalProfit}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{admin.wallet}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <details>
                      <summary className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded-md cursor-pointer">View Stats</summary>
                      <table className="min-w-full divide-y divide-gray-200 mt-2">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Period</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Profit</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {admin.statsByPeriod.map((stat, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap">{new Date(stat.date).toLocaleDateString()}</td>
                              <td className="px-6 py-4 whitespace-nowrap">{stat.profit}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </details>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{getTodaysProfit(admin)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{Math.round(getThisWeekAverage(admin))}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {getAverageDailyProfit(admin)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{Math.round(getMaxProfit(admin))}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {editingAdminId === admin._id ? (
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="number"
                          value={addAmount}
                          onChange={handleAddAmountChange}
                          className="flex-1 min-w-0 block w-full px-3 py-2 rounded-none rounded-l-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300"
                          placeholder="Add amount"
                        />
                        <button onClick={() => addToWallet(admin._id)} className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-indigo-600 text-white text-sm font-medium hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          Add
                        </button>
                        <button onClick={() => setEditingAdminId(null)} className="ml-2 inline-flex items-center px-3 rounded-md border border-gray-300 bg-gray-200 text-gray-700 text-sm font-medium hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          Cancel
                        </button>
                      </div>
                    ) : (
                      <button onClick={() => handleEditWallet(admin._id)} className="px-2 py-1 text-sm font-medium text-white bg-yellow-500 rounded-md">
                        Edit
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => fetchLastTenGames(admin._id, admin.username)}
                      className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded-md"
                    >
                      Last 10 Games
                    </button>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">{getLastWalletEditAmount(admin)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{getLastWalletEditDate(admin)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{getAverageDaysBetweenEdits(admin)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{getAverageCreditAmount(admin)}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <details>
                      <summary className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded-md cursor-pointer">View Wallet Edit History</summary>
                      <table className="min-w-full divide-y divide-gray-200 mt-2">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date</th>
                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {admin.walletEditHistory.map((edit, index) => (
                            <tr key={index}>
                              <td className="px-6 py-4 whitespace-nowrap">{new Date(edit.date).toLocaleDateString()}</td>
                              <td className="px-6 py-4 whitespace-nowrap">{edit.amount}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </details>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {adminData.find((data) => data.adminId === admin._id)?.averageTimeBetweenGamesToday || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {adminData.find((data) => data.adminId === admin._id)?.averageTimeBetweenGamesWeek || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {adminData.find((data) => data.adminId === admin._id)?.averageTimeBetweenGamesMonth || '-'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {adminData.find((data) => data.adminId === admin._id)?.averageTimeBetweenGames || '-'}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    {highestNumberOfPlayersData.find((data) => data.adminId === admin._id)?.today || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {highestNumberOfPlayersData.find((data) => data.adminId === admin._id)?.week || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {highestNumberOfPlayersData.find((data) => data.adminId === admin._id)?.month || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {highestNumberOfPlayersData.find((data) => data.adminId === admin._id)?.allTime || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {highestNumberOfPlayersData.find((data) => data.adminId === admin._id)?.highestProfitToday || 0}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {highestNumberOfPlayersData.find((data) => data.adminId === admin._id)?.highestProfitAllTime || 0}
                  </td>

                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => handleBlockUnblockAdmin(admin._id, admin.isBlocked)}
                      className={`px-2 py-1 text-sm font-medium rounded-md ${
                        admin.isBlocked
                          ? 'text-white bg-green-500 hover:bg-green-600'
                          : 'text-white bg-red-500 hover:bg-red-600'
                      }`}
                    >
                      {admin.isBlocked ? 'Unblock' : 'Block'}
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
      )}
          <Modal
            show={lastTenGames.length > 0}
            onHide={() => setLastTenGames([])}
            username={lastTenGames.length > 0 ? lastTenGames[0].admin.username : ''}
            size="xl"
          >
            <Modal.Header closeButton>
              <Modal.Title>Last 10 Games - {lastTenGamesUsername}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">EndDate</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Betting Amount</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Number of Players</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Winning Amount</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Profit</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {lastTenGames.map((game) => (
                    <tr key={game._id}>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {new Date(game.createdAt).toLocaleDateString('en-US', {
                          weekday: 'long',
                          year: 'numeric',
                          month: 'long',
                          day: 'numeric',
                        })}{' '}
                        {new Date(game.createdAt).toLocaleTimeString('en-US', {
                          hour: '2-digit',
                          minute: '2-digit',
                          second: '2-digit',
                        })}
                      </td>
                      <td>
                        {game.endedAt ? (
                          new Date(game.endedAt).toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit',
                          })
                        ) : (
                          '-'
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">{game.bettingAmount}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{game.numberOfPlayers}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{game.payoutToWinner}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{game.bettingAmount * game.numberOfPlayers * 0.2}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{game.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </Modal.Body>
          </Modal>
      </div>
      )}
    </div>
  );
};

export default AdminTable;
