import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import AdminStats from './Stats';
import AllCards from './AllCards';
import AdminGames from './myGames';
import InsertCards from './InsertCards';
import GenerateCards from './GenerateCards';
import { FaUser, FaTachometerAlt, FaClipboard, FaFileAlt, FaCog, FaArrowLeft } from 'react-icons/fa';

const Report = () => {
  const [activeComponent, setActiveComponent] = useState('AdminGames');
  const navigate = useNavigate(); // Initialize the navigate function
 
  const renderComponent = () => {
    switch (activeComponent) {
      case 'AdminStats':
        return <AdminStats />;
      case 'AllCards':
        return <AllCards />;
      case 'AdminGames':
        return <AdminGames />;
      case 'InsertCards':
        return <InsertCards />;
      case 'GenerateCards':
        return <GenerateCards />;
      default:
        return null;
    }
  };

  const handleNavClick = (componentName) => {
    if (componentName === 'InsertCards') {
      const password = prompt('Please enter the password to access Insert Cards:');
      if (password === '##909090') {
        setActiveComponent(componentName);
      } else {
        alert('Incorrect password. Access denied.');
      }
    } else {
      setActiveComponent(componentName);
    }
  };

  const handleBack = () => {
    navigate('/dashboard');
  };

  const displayName = window.location.href.includes('power') ? 'Haimanot' : 'Shashemene';

  return (
    <div style={{ display: 'flex', minHeight: '100vh' }} className='bg-yellow-900'>
      <div
        style={{
          width: '200px',
          color: '#fff',
          padding: '20px',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '5px',
        }}
        className='mx-5 my-5 rounded-3 bg-yellow-600'
      >
        <button
          className="bg-yellow-600 text-white"
          onClick={handleBack}
        >
          <FaArrowLeft className="mr-2 mb-2" />
        </button>
         <div className='d-flex mt-2'>
            <div
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: '#ccc',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <FaUser
              />
            </div>
            <h2 style={{ fontSize: '15px', marginBottom: '10px', marginLeft: '15px' }}>Admin Dashboard</h2>
            </div>
        <div className='d-flex flex-column mt-4'>
          <ul style={{ listStyle: 'none', padding: 0 }}>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AdminGames')}
              >
                Stats
              </button>
            </li>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AllCards')}
              >
                Cartella
              </button>
            </li>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('AdminStats')}
              >
                Daily Profit
              </button>
            </li>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('InsertCards')}
              >
                Insert Cards
              </button>
            </li>
            <li style={{ marginBottom: '20px' }}>
              <button
                style={{
                  backgroundColor: 'transparent',
                  color: '#fff',
                  border: 'none',
                  fontSize: '16px',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                }}
                onClick={() => handleNavClick('GenerateCards')}
              >
                Generate Cards
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div style={{ marginLeft: '220px', padding: '20px', width: 'calc(100% - 220px)' }}>
        {renderComponent()}
      </div>
    </div>
  );
};

export default Report;
