import React from 'react';

function Card({ card, onEditClick }) {
  return (
    <div className="mt-6 mx-auto p-2 rounded-lg font-bold text-2xl bg-yellow-400 max-w-md">
      <h2 className="text-center mb-0 text-white fw-bold" style={{ letterSpacing: '65px', marginLeft: '43px' }}>BINGO</h2>
      <div className="grid grid-cols-5 gap-0">
        <div className="text-gray-800 border border-yellow-400 p-0 text-center">
          {card.numbers.B.map(num => (
            <div className="bg-white text-gray-800 border border-yellow-400">{num}</div>
          ))}
        </div>
        <div className="text-gray-800 border border-yellow-400 p-0 text-center">
          {card.numbers.I.map(num => (
            <div className="bg-white text-gray-800 border border-yellow-400">{num}</div>
          ))}
        </div>
        <div className="text-gray-800 border border-yellow-400 p-0 text-center">
          {card.numbers.N.map((num, index) => (
            <div className={`bg-white text-gray-800 border ${index === 2 ? 'bg-yellow-400 text-yellow-400' : ''}`}>
              {num}
            </div>
          ))}
        </div>
        <div className="text-gray-800 border border-yellow-400 p-0 text-center">
          {card.numbers.G.map(num => (
            <div className="bg-white text-gray-800 border border-yellow-400">{num}</div>
          ))}
        </div>
        <div className="text-gray-800 border border-yellow-400 p-0 text-center">
          {card.numbers.O.map(num => (
            <div className="bg-white text-gray-800 border border-yellow-400">{num}</div>
          ))}
        </div>
      </div>
      <h3 className="text-center text-lg mt-2">Card No. {card.paletteNumber}</h3>
      <button
        className="bg-yellow-600 bg-yellow-800 text-white font-bold py-1 px-1 rounded fs-6"
        onClick={() => onEditClick(card)}
      >
        Edit
      </button>
    </div>
  );
}

export default Card;