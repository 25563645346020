import React, { useState, useEffect } from 'react';

function AdminStats() {
  const API = process.env.REACT_APP_API;
  const [stats, setStats] = useState({
    statsByPeriod: [],
    totalProfit: 0,
    totalBetAmount: 0,
    totalGames: 0,
  });

  useEffect(() => {
    const fetchStats = async () => {
      const token = localStorage.getItem('token');
      const response = await fetch(`${API}/admin/stats`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      const data = await response.json();
      setStats(data);
    };
    fetchStats();
  }, []);

  function formatDate(dateStr) {
    const date = new Date(dateStr);
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December',
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  // Combine profits for entries with the same date and round to nearest integer
  const combinedStats = {};
  stats.statsByPeriod.forEach((period) => {
    const dateStr = formatDate(period.date);
    if (!combinedStats[dateStr]) {
      combinedStats[dateStr] = 0;
    }
    combinedStats[dateStr] += period.profit;
  });

  // Round profit to nearest integer
  Object.entries(combinedStats).forEach(([date, profit]) => {
    combinedStats[date] = Math.round(profit);
  });

  return (
    <div className="m-4 p-4">
      <div className="overflow-x-auto">
        <table className="w-full text-left border-collapse bg-white rounded-lg shadow-lg">
          <thead>
            <tr className="bg-yellow-500 text-white">
              <th className="px-6 py-3">Date</th>
              <th className="px-6 py-3">Profit</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(combinedStats).map(([date, profit]) => (
              <tr key={date} className="border-b border-gray-200 hover:bg-yellow-100">
                <td className="px-6 py-4 whitespace-nowrap">{date}</td>
                <td className="px-6 py-4 whitespace-nowrap">{profit} Birr</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default AdminStats;