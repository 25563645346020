import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './BingoCard.css'

const BingoCard = ({ cardNumber, calledNumbers, isWinner }) => {
  const [cards, setCards] = useState([]);
  const [B, setB] = useState([]);
  const [I, setI] = useState([]);
  const [N, setN] = useState([]);
  const [G, setG] = useState([]);
  const [O, setO] = useState([]);
  const [row1 , setRow1] = useState([])
  const [row2 , setRow2] = useState([])
  const [row3 , setRow3] = useState([])
  const [row4 , setRow4] = useState([])
  const [row5 , setRow5] = useState([])
  const [diag1, setDiag1] = useState([])
  const [diag2, setDiag2] = useState([])
  const [corner, setCorner] = useState([])
  const [surr, setSurr] = useState([])
  const API = process.env.REACT_APP_API;
  
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchCards() {
      try {
        const response = await fetch(`${API}/card/getCards`);
        const data = await response.json();
        const cards = data; // Replace with the array of cards from the API response

        const foundCard = cards.find((card) => card.paletteNumber == cardNumber);
        console.log(foundCard);
        setCards(foundCard);
        setLoading(false); // Set loading to false after cards are fetched

        if (foundCard) {
          console.log('Card found:', foundCard);
          setB(foundCard.numbers.B);
          setI(foundCard.numbers.I);
          setN(foundCard.numbers.N);
          setG(foundCard.numbers.G);
          setO(foundCard.numbers.O);
          setRow1([foundCard.numbers.B[0], foundCard.numbers.I[0], foundCard.numbers.N[0], foundCard.numbers.G[0], foundCard.numbers.O[0]]);
          setRow2([foundCard.numbers.B[1], foundCard.numbers.I[1], foundCard.numbers.N[1], foundCard.numbers.G[1], foundCard.numbers.O[1]]);
          setRow3([foundCard.numbers.B[2], foundCard.numbers.I[2], foundCard.numbers.G[2], foundCard.numbers.O[2]]);
          setRow4([foundCard.numbers.B[3], foundCard.numbers.I[3], foundCard.numbers.N[3], foundCard.numbers.G[3], foundCard.numbers.O[3]]);
          setRow5([foundCard.numbers.B[4], foundCard.numbers.I[4], foundCard.numbers.N[4], foundCard.numbers.G[4], foundCard.numbers.O[4]]);
          setDiag1([foundCard.numbers.B[0], foundCard.numbers.I[1], foundCard.numbers.G[3], foundCard.numbers.O[4]]);
          setDiag2([foundCard.numbers.O[0], foundCard.numbers.G[1], foundCard.numbers.I[3], foundCard.numbers.B[4]]);

          setCorner([foundCard.numbers.B[0], foundCard.numbers.B[4], foundCard.numbers.O[0], foundCard.numbers.O[4]])
          setSurr([foundCard.numbers.I[1], foundCard.numbers.I[3], foundCard.numbers.G[1], foundCard.numbers.G[3]])
        } else {
          console.log('Card not found');
        }
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    }
    fetchCards();
  }, []);

  const checkWinningPattern = () => {

    let cornerPattern = calledNumbers.includes(`B${corner[0]}`) && calledNumbers.includes(`B${corner[1]}`) && calledNumbers.includes(`O${corner[2]}`) && calledNumbers.includes(`O${corner[3]}`);
    let surrPattern = calledNumbers.includes(`I${surr[0]}`) && calledNumbers.includes(`I${surr[1]}`) && calledNumbers.includes(`G${surr[2]}`) && calledNumbers.includes(`G${surr[3]}`);

    let firstDiag = calledNumbers.includes(`B${diag1[0]}`) && calledNumbers.includes(`I${diag1[1]}`) && calledNumbers.includes(`G${diag1[2]}`) && calledNumbers.includes(`O${diag1[3]}`);
    let secondDiag = calledNumbers.includes(`O${diag2[0]}`) && calledNumbers.includes(`G${diag2[1]}`) && calledNumbers.includes(`I${diag2[2]}`) && calledNumbers.includes(`B${diag2[3]}`);
    // // // Check columns
    let Bcolumn = B.every((num) => calledNumbers.includes(`B${num}`));
    let Icolumn = I.every((num) => calledNumbers.includes(`I${num}`));
    let Ncolumn = N.every((num) => calledNumbers.includes(`N${num}`));
    let Gcolumn = G.every((num) => calledNumbers.includes(`G${num}`));
    let Ocolumn = O.every((num) => calledNumbers.includes(`O${num}`));

    let firstRow = calledNumbers.includes(`B${row1[0]}`) && calledNumbers.includes(`I${row1[1]}`) && calledNumbers.includes(`N${row1[2]}`) && calledNumbers.includes(`G${row1[3]}`) && calledNumbers.includes(`O${row1[4]}`);
    let secondRow = calledNumbers.includes(`B${row2[0]}`) && calledNumbers.includes(`I${row2[1]}`) && calledNumbers.includes(`N${row2[2]}`) && calledNumbers.includes(`G${row2[3]}`) && calledNumbers.includes(`O${row2[4]}`);
    let thirdRow = calledNumbers.includes(`B${row3[0]}`) && calledNumbers.includes(`I${row3[1]}`) && calledNumbers.includes(`G${row3[2]}`) && calledNumbers.includes(`O${row3[3]}`);
    let fourthRow = calledNumbers.includes(`B${row4[0]}`) && calledNumbers.includes(`I${row4[1]}`) && calledNumbers.includes(`N${row4[2]}`) && calledNumbers.includes(`G${row4[3]}`) && calledNumbers.includes(`O${row4[4]}`);
    let fifthRow = calledNumbers.includes(`B${row5[0]}`) && calledNumbers.includes(`I${row5[1]}`) && calledNumbers.includes(`N${row5[2]}`) && calledNumbers.includes(`G${row5[3]}`) && calledNumbers.includes(`O${row5[4]}`);

    if (cornerPattern) {
      surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (surrPattern) {
      cornerPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (firstDiag) {
      cornerPattern = surrPattern = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (secondDiag) {
      cornerPattern = surrPattern = firstDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (Bcolumn) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (Icolumn) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (Ncolumn) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (Gcolumn) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (Ocolumn) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = firstRow = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (firstRow) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = secondRow = thirdRow = fourthRow = fifthRow = false;
    } else if (secondRow) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = thirdRow = fourthRow = fifthRow = false;
    } else if (thirdRow) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = fourthRow = fifthRow = false;
    } else if (fourthRow) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fifthRow = false;
    } else if (fifthRow) {
      cornerPattern = surrPattern = firstDiag = secondDiag = Bcolumn = Icolumn = Ncolumn = Gcolumn = Ocolumn = firstRow = secondRow = thirdRow = fourthRow = false;
    }

    
    return {
      Bcolumn,
      Icolumn,
      Ncolumn,
      Gcolumn,
      Ocolumn,
      firstRow,
      secondRow,
      thirdRow,
      fourthRow,
      fifthRow,
      firstDiag,
      secondDiag,
      cornerPattern,
      surrPattern,
    };
  };

  const winningPattern = checkWinningPattern();
  

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="h-100">
      <h1 className={`text-center mb-4 ${isWinner ? 'text-success' : 'text-danger'} fw-bold`}>
        {cards.paletteNumber} {isWinner ? 'ዘግቷል' : 'አልዘጋም'}
      </h1>

      <Row className="mx-auto">
        <Col xs={2} className="text-primary border p-0 text-center">
          <h1 className={`bg-primary text-white mb-0`}>B</h1>
          {cards.numbers.B.map((num, index) => (
            <div
              className={`text-primary fw-bold border fs-1 ${
                calledNumbers.includes(`B${num}`) && !isWinner ? 'bg-success text-white' : 'bg-white'
              } ${
                (winningPattern.Bcolumn ||
                  (index === 0 && winningPattern.firstRow) ||
                  (index === 1 && winningPattern.secondRow) ||
                  (index === 2 && winningPattern.thirdRow) ||
                  (index === 3 && winningPattern.fourthRow) ||
                  (index === 4 && winningPattern.fifthRow) ||
                  (index === 0 && winningPattern.firstDiag) ||
                  (index === 4 && winningPattern.secondDiag) ||
                  ((index === 0 || index === 4) && winningPattern.cornerPattern))
                  ? 'winning-cell'
                  : ''
              }`}
              key={num}
            >
              {num}
            </div>
          ))}
        </Col>

        <Col xs={2} className="text-danger border p-0 text-center">
          <h1 className={`bg-danger text-white mb-0`}>I</h1>
          {cards.numbers.I.map((num, index) => (
            <div
              className={`text-primary fw-bold border fs-1 ${
                calledNumbers.includes(`I${num}`) && !isWinner ? 'bg-success text-white' : 'bg-white'
              } ${
                (winningPattern.Icolumn ||
                  (index === 0 && winningPattern.firstRow) ||
                  (index === 1 && (winningPattern.secondRow || winningPattern.firstDiag || winningPattern.surrPattern)) ||
                  (index === 2 && winningPattern.thirdRow) ||
                  (index === 3 && (winningPattern.fourthRow || winningPattern.secondDiag || winningPattern.surrPattern)) ||
                  (index === 4 && winningPattern.fifthRow))
                  ? 'winning-cell'
                  : ''
              }`}
              key={num}
            >
              {num}
            </div>
          ))}
        </Col>

        <Col xs={3} className="text-warning border p-0 text-center">
          <h1 style={{ backgroundColor: 'orange' }} className={`text-white mb-0`}>
            N
          </h1>
          {cards.numbers.N.map((num, index) => (
            <div
              className={`text-primary fw-bold border fs-1 ${
                (calledNumbers.includes(`N${num}`) && !isWinner) ||
                (num === 'FREE' && !isWinner) ||
                (num === 'FREE' &&
                  (winningPattern.Ncolumn ||
                    (index === 0 && winningPattern.firstRow) ||
                    (index === 1 && winningPattern.secondRow) ||
                    (index === 2 && winningPattern.thirdRow) ||
                    (index === 3 && winningPattern.fourthRow) ||
                    (index === 4 && winningPattern.fifthRow) ||
                    winningPattern.firstDiag ||
                    winningPattern.secondDiag))
                  ? 'bg-success text-white'
                  : 'bg-white'
              } ${
                winningPattern.Ncolumn ||
                (index === 0 && winningPattern.firstRow) ||
                (index === 1 && winningPattern.secondRow) ||
                (index === 3 && winningPattern.fourthRow) ||
                (index === 2 && winningPattern.thirdRow) ||
                (index === 4 && winningPattern.fifthRow)
                  ? 'winning-cell'
                  : ''
              }`}
              key={num}
            >
              {num}
            </div>
          ))}
        </Col>

        <Col xs={2} className="text-success border p-0 text-center">
          <h1 className={`bg-success text-white mb-0`}>G</h1>
          {cards.numbers.G.map((num, index) => (
            <div
              className={`text-primary fw-bold border fs-1 ${
                calledNumbers.includes(`G${num}`) && !isWinner ? 'bg-success text-white' : 'bg-white'
              } ${
                (winningPattern.Gcolumn ||
                  (index === 0 && winningPattern.firstRow) ||
                  (index === 1 && (winningPattern.secondRow || winningPattern.secondDiag || winningPattern.surrPattern)) ||
                  (index === 2 && winningPattern.thirdRow) ||
                  (index === 3 && (winningPattern.fourthRow || winningPattern.firstDiag || winningPattern.surrPattern)) ||
                  (index === 4 && winningPattern.fifthRow))
                  ? 'winning-cell'
                  : ''
              }`}
              key={num}
            >
              {num}
            </div>
          ))}
        </Col>

        <Col xs={2} className="text-info border p-0 text-center">
          <h1 className={`bg-warning text-white mb-0`}>O</h1>
          {cards.numbers.O.map((num, index) => (
            <div
              className={`text-primary fw-bold border fs-1 ${
                calledNumbers.includes(`O${num}`) && !isWinner ? 'bg-success text-white' : 'bg-white'
              } ${
                (winningPattern.Ocolumn ||
                  (index === 0 && (winningPattern.firstRow || winningPattern.secondDiag || winningPattern.cornerPattern)) ||
                  (index === 1 && winningPattern.secondRow) ||
                  (index === 2 && winningPattern.thirdRow) ||
                  (index === 3 && winningPattern.fourthRow) ||
                  (index === 4 && (winningPattern.fifthRow || winningPattern.firstDiag || winningPattern.cornerPattern)))
                  ? 'winning-cell'
                  : ''
              }`}
              key={num}
            >
              {num}
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};

export default BingoCard;