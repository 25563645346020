import React, { useState, useEffect } from 'react';
import Card from './Card';
import EditCard from './EditCard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AllCards() {
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const API = process.env.REACT_APP_API;

  useEffect(() => {
    async function fetchCards() {
      try {
        const response = await fetch(`${API}/card/getCards`,
         { headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        });
        const data = await response.json();
        const sortedCards = data.sort((a, b) => a.paletteNumber - b.paletteNumber);
        setCards(sortedCards);
      } catch (error) {
        console.error('Error fetching cards:', error);
      }
    }
    fetchCards();
  }, []);

  const handleEditClick = (card) => {
    const password = prompt('Please enter the password to edit the card:');
    if (password === '##909090') {
      setSelectedCard(card);
      setShowModal(true);
    } else {
      toast.error('Incorrect password. Edit access denied.');
    }
  };

  const handleCloseModal = () => {
    setSelectedCard(null);
    setShowModal(false);
  };

  return (
    <div>
      <ToastContainer />
      {cards.map(card => (
        <div key={card.paletteNumber}>
          <Card card={card} onEditClick={handleEditClick} />
        </div>
      ))}
      {showModal && (
        <EditCard
          card={selectedCard}
          onClose={handleCloseModal}
          onUpdate={(updatedCard) => {
            setCards((prevCards) =>
              prevCards.map((card) =>
                card.paletteNumber === updatedCard.paletteNumber ? updatedCard : card
              )
            );
            handleCloseModal();
          }}
        />
      )}
    </div>
  );
}

export default AllCards;